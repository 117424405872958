import { GenericDataApi } from '@/lib/data/GenericDataApi';
import {
    AssignShiftOwnerActionType,
    AssignShiftOwnerCommand, CreateDepartmentCommand, CreateMineAreaCommand, ExternalNotificationSubscriberViewModel,
    MineArea,
    PropertyDefinitionModel, UpdateDepartmentCommand, UpdateDepartmentNotificationSubscribersCommand,
    UpdateMineAreaCommand,
    DepartmentMenuOptionsViewModel,
} from '@/models/api';
import { Department } from '@/models/api';
import { GetShiftOwnerQueryResponse } from '@/models/api/Queries/get-shift-owner-query-response';
import AppHttp from '../AppHttp';

export class Departments {
    public BoardTypes = [
        { value: 0, text: 'Duration-based (e.g. Development)' },
        { value: 1, text: 'Rate-based (e.g. Production)' },
        { value: 2, text: 'Ad-hoc Tasks (e.g. Services)' },
        { value: 3, text: 'Combined' }
    ];

    private resource: string = 'Departments';

    public async getById(id: string): Promise<Department> {
        const response = await AppHttp.get(`${this.resource}/${id}`);
        const data = response ? response.data : null;
        return data;
    }

    public async getSubscribersById(id: string): Promise<ExternalNotificationSubscriberViewModel[]> {
        const response = await AppHttp.get(`${this.resource}/${id}/Subscribers`);
        const data = response ? response.data : null;
        return data;
    }

    public async updateSubscribers(command: UpdateDepartmentNotificationSubscribersCommand) {
        const response = await AppHttp.post(`${this.resource}/${command.departmentId}/Subscribers`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async get(include?: string[]): Promise<Department[]> {
        let query = '';
        if (include) {
            const p: string = include.map((x) => `include=${x}`).join('&');
            query = '?' + p;
        }
        const response = await AppHttp.get(this.resource + query);
        const data = response ? response.data : null;
        return data;
    }

    public async update(command: UpdateDepartmentCommand) {
        const response = await AppHttp.post(`${this.resource}/Update`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async create(command: CreateDepartmentCommand) {
        const response = await AppHttp.post(`${this.resource}/Create`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async delete(id: string) {
        const response = await AppHttp.delete(`${this.resource}/${id}`);
        const data = response ? response.data : null;
        return data;
    }

    async getShiftOwner(departmentId: string): Promise<GetShiftOwnerQueryResponse> {
        const response = await AppHttp.get(`${this.resource}/${departmentId}/ShiftOwner`);
        const data = response ? response.data : null;
        return data;
    }

    async setShiftOwner(request: AssignShiftOwnerCommand) {
        let endpoint = 'SetShiftOwner';
        switch (request.actionType) {
            case AssignShiftOwnerActionType.Assign:
                endpoint = 'SetShiftOwner';
                break;
            case AssignShiftOwnerActionType.Handover:
                endpoint = 'ShiftOwnerHandover';
                break;
            default:
                endpoint = 'TakeShiftOwnership';
                break;
        }
        const response = await AppHttp.post(`${this.resource}/${endpoint}/`, request);
        const data = response ? response.data : null;
        return data;
    }

    public async getMenuOptions(): Promise<DepartmentMenuOptionsViewModel[]> {
        const response = await AppHttp.get(`${this.resource}/MenuOptions`);
        const data = response ? response.data : null;
        return data.departments;
    }

    public async GetPropertyDefinitions(departmentId: string, includeMineAreaProperties: Boolean = false) : Promise<PropertyDefinitionModel[]>
    {
        const response = await AppHttp.get(`${this.resource}/${departmentId}/propertydefinitions?includeMineAreaProperties=${includeMineAreaProperties}`);

        const data = response ? response.data : [] as PropertyDefinitionModel[];
        return data.extendedProperties;
    }
}

export default new Departments();
