<template>
    <standard-dialog :value='dialog' :max-width="600" persistent>
        <template v-slot:title>
            Select {{ 'Locations' | term }}
        </template>
        <template v-slot:content>
            <v-form ref="form" :lazy-validation="true">
                <v-container fluid v-if="locations">
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                :items="filteredLocations"
                                item-value="id"
                                item-text="name"
                                :rules="[$rules.required()]"
                                :label="$termSync('Location')"
                                v-model="selectedLocations"
                                :menu-props="{ dark: true, contentClass:'with-prepend', maxHeight:'600' }"
                                multiple
                                ref="locations"
                            >
                                <template v-slot:item="{ item, attrs }">
                                    <v-icon class="mr-7">
                                        {{ attrs.inputValue ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                                    </v-icon>
                                    <v-chip small class="mr-2">{{ item.displayOrder }}</v-chip>
                                    {{ item.name }}
                                </template>
                                <template v-slot:prepend-item>
                                    <div class="prepend theme--dark v-sheet">
                                        <v-list-item ripple>
                                            <v-list-item-content class="pa-0">
                                                <v-list-item-title class="d-flex">
                                                    <v-text-field outlined dense hide-details class="mt-1" label="Search" v-model="locationFilter" clearable />
                                                    <v-spacer></v-spacer>
                                                    <v-btn text small @click="$refs['locations'].blur()"> <v-icon class="mr-2">mdi-close</v-icon>Close </v-btn>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="my-2"></v-divider>
                                        <v-list-item ripple @click="toggle">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-icon class="mr-7">{{ icon }}</v-icon
                                                    >Select All
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </div>
                                </template>
                            </v-select>
                            <v-col cols='12'>
                                <v-switch dense v-model="replaceExisting" label="Replace Existing Locations"></v-switch>
                            </v-col>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </template>
        <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn color="gray" text @click="onCancel">Cancel</v-btn>
            <v-btn color="primary" text @click="select()" :loading="$wait.is('saving')"
            >Select</v-btn
            >
        </template>
        <template v-slot:offline-actions>
            <v-spacer></v-spacer>
            <v-btn color="gray" text @click="onCancel">Cancel</v-btn>
            <offline-btn text></offline-btn>
        </template>
    </standard-dialog>
</template>

<script>
import Vue from 'vue';
import { enumOptions } from '@/lib/EnumExtensions';
import { BoardType, CycleType } from '@/models/api';
import { waitFor } from 'vue-wait';
import Locations from '@/lib/data/Locations';
import { MineAreaStore } from '@/lib/stores/MineAreaStore';

export default Vue.extend({
    props: ['mineAreaId', 'departmentId'],
    data() {
        return {
            selectedLocations: [],
            locations: null,
            dialog: false,
            existing: [],
            locationFilter: '',
            replaceExisting: false,
            BoardType
        };
    },
    methods: {
        async show(existing) {
            this.existing = existing;
            this.dialog = true;
            this.selectedLocations = [];
            this.replaceExisting = false;
            await this.loadData();
            setTimeout(() => this.$refs.form.resetValidation(), 1);
        },
        select: waitFor('saving', async function() {
            if (!this.$refs.form.validate()) {
                return;
            }
            if (this.selectedLocations && this.selectedLocations.length) {
                this.$emit('selected', this.selectedLocations, this.replaceExisting);
            }
            this.dialog = false;
        }),
        onCancel() {
            this.dialog = false;
            this.$emit('canceled');
        },
        async loadData() {
            const allLocations = await Locations.getByDepartment(this.departmentId);
            this.locations = allLocations.filter(location => location.parentLocationId === null);
        },
        toggle() {
            this.$nextTick(() => {
                if (this.allSelected) {
                    this.selectedLocations = [];
                } else {
                    this.selectedLocations = this.filteredLocations.map(x => x.id).slice();
                }
            });
        }
    },
    computed: {
        filteredLocations() {
            if(!this.locations)
                return [];

            return this.locations
                .filter(x => !this.existing.filter(e => e == x.id).length)
                .filter(x => !this.locationFilter || x.name.includes(this.locationFilter))
                .sort((a, b) => {
                    if (a.displayOrder == b.displayOrder) {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    }
                    return a.displayOrder - b.displayOrder;
                });
        },
        allSelected() {
            return this.selectedLocations.length === this.filteredLocations.length;
        },
        someSelected() {
            return this.selectedLocations.length > 0 && !this.allSelected;
        },
        icon() {
            if (this.allSelected) return 'mdi-checkbox-marked';
            if (this.someSelected) return 'mdi-minus-box';
            return 'mdi-checkbox-blank-outline';
        }
    }
})
</script>

<style scoped>

</style>