<template>
    <v-menu offset-y v-if="isLoggedIn">
        <template v-slot:activator="{ on }">
            <v-btn color="white" dark icon v-on="on">
                <v-icon>mdi-cog</v-icon>
            </v-btn>
        </template>
        <v-list class="pb-0">
            <v-list-item class="px-5 py-2">
                <v-list-item-content>
                    <v-list-item-title class="overline">{{ username }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-access-control v-if="showTenantChange" ignore-read-only>
                <v-divider></v-divider>
                <template #offline>
                <v-list-item class="px-5 py-2" disabled color="primary">
                    <v-list-item-content>
                        <v-list-item-title class="body-2">Change Org/Mine</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                </template>
                <v-list-item class="px-5 py-2" @click="changeTenant()" color="primary">
                    <v-list-item-content>
                        <v-list-item-title class="body-2">Change Org/Mine</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-access-control>
            <v-divider></v-divider>
            <v-list-item class="px-5 py-2" @click="$nav('/logout')" color="primary">
                <v-list-item-content>
                    <v-list-item-title class="body-2">Logout</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-access-control :grantAccessIf="isAdmin">
                <template #offline>
                    <v-list-item class="px-5 py-2" color="grey" disabled>
                        <v-list-item-content>
                            <v-list-item-title class="body-2">Administration</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </template>
                <v-list-item class="px-5 py-2" :to="{ name: 'adminhome' }" color="primary">
                    <v-list-item-content>
                        <v-list-item-title class="body-2">Administration</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </v-access-control>
            <v-list-item class="px-5 py-2" color="primary">
                <v-list-item-content>
                    <v-list-item-title class="body-2">
                        <v-btn
                            text
                            icon
                            @click="size('s')"
                            :color="this.screenSize == 's' ? 'primary' : ''"
                            :class="class_s"
                        >
                            <v-icon>mdi-tablet</v-icon>
                        </v-btn>
                        <v-btn
                            text
                            icon
                            @click="size('m')"
                            :color="this.screenSize == 'm' ? 'primary' : ''"
                            :class="class_m"
                        >
                            <v-icon>mdi-laptop</v-icon>
                        </v-btn>
                        <v-btn
                            text
                            icon
                            @click="size('l')"
                            :color="this.screenSize == 'l' ? 'primary' : ''"
                            :class="class_l"
                        >
                            <v-icon>mdi-television</v-icon>
                        </v-btn>
                        <v-btn
                            text
                            icon
                            @click="size('x')"
                            :color="this.screenSize == 'x' ? 'primary' : ''"
                            :class="class_x"
                        >
                            <v-icon large>mdi-television</v-icon>
                        </v-btn>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-access-control v-if="showHelpLink" :offline-access="false">
                <template #offline>
                    <v-list-item class="px-5 py-2" color="grey" disabled>
                        <v-list-item-content>
                            <v-list-item-title class="body-2">Help</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </template>
                <v-list-item class="px-5 py-2" href="https://academy.ordodeploy.com/en/support/home" target="_target" color="primary">
                    <v-list-item-content>
                        <v-list-item-title class="body-2">Help</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon>mdi-help-circle</v-icon>
                    </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
            </v-access-control>
            <v-list-item dense dark class="darkbg">
                <v-list-item-content>
                    <v-list-item-title class="caption overflow-text"
                        >Version {{ applicationVersion }}</v-list-item-title
                    >
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
import UserStore from '@/lib/stores/UserStore';
import AuthService from '@/lib/Auth';
import Security from '@/lib/Security';
import { Events, EventBus } from '@/lib/EventBus';
import Config from '@/lib/Config';

export default {
    data() {
        return { isAdmin: false, isLoggedIn: false, screenSize: 'm', applicationVersion: Config.ApplicationVersion, username: '' };
    },
    async created() {
        var user = await UserStore.getCurrentUser();
        this.username = user.name;
        this.isAdmin = Security.isAnyAdmin();
        this.isLoggedIn = !!user;
        this.screenSize = localStorage.getItem('screenSize') || 'm';
    },
    methods: {
        size(size) {
            this.screenSize = size;
            EventBus.$emit(Events.ScreenSizeChanged, size);
        },
        changeTenant() {
            EventBus.$emit(Events.ShowTenantChange);
        }
    },
    computed: {
        showHelpLink() {
            return Config?.FeatureFlags?.DisplayMainMenuHelpLink ?? false;
        },
        showTenantChange() {
            var jwtTenants = AuthService.getTenants();
            if (!jwtTenants || jwtTenants.length < 2) return false;
            return true;
        },
        class_s() {
            return {
                'v-btn--active': this.screenSize == 's'
            };
        },
        class_m() {
            return {
                'v-btn--active': this.screenSize == 'm'
            };
        },
        class_l() {
            return {
                'v-btn--active': this.screenSize == 'l'
            };
        },
        class_x() {
            return {
                'v-btn--active': this.screenSize == 'x'
            };
        }
    }
};
</script>
<style scoped>
.tile {
    background: white;
}
.tile:hover {
    background: green;
}
.tile:active {
    background: yellow;
}
.darkbg {
    background-color: #272727;
}
</style>
